import logo from './assets/logo.png'; // Ruta a tu archivo de imagen del logo
import './App.css';
import React, { useEffect, useState } from 'react';



function App() {
  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    setShowDiv(true);
  }, []);
  return (
    <div className="App">

      <body>

        <div class="logo">
          <img src={logo} className="logo" alt="Logo" />
        </div>
        <div class="leyenda">Diseñamos y construimos stands</div>

        <div class="contacto">
          <a href="https://w.app/AotVDr" class="button">CONTACTO</a>
        </div>

        <div className={`slide-from-bottom ${showDiv ? 'visible' : ''}`}>
        <table class= "blanco">
          <tr>
            <th>Oficina</th>
            <th>Álvarez thomas 1120 1 b, Bs As,  Argentina.</th>
          </tr>
          <tr>
            <td className= "blanco" translate="no">Behance</td>
            <td><a href="https://www.behance.net/studio4ss">@studio4ss</a></td>
          </tr>
          <tr>
            <td>Instagram</td>
            <td><a href="https://www.instagram.com/studio_4ss/">@studio_4ss</a></td>
          </tr>
          <tr>
            <td class= "blanco">E-mail</td>
            <td><a href="mailto:hola.studio4ss@gmail.com">hola.studio4ss@gmail.com</a></td>
          </tr>
        </table>       
        </div>

      </body>
    </div>
  );
}

export default App;
